import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import {desktop_login} from "../slices/auth";

const DesktopLogin = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");


  const dispatch = useDispatch();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const msgCode = urlSearchParams.get("code")
    if (msgCode === "401") {
      setMessage("Invalid Desktop credentials!");
    }
    const clientId = urlSearchParams.get("client");
    const machineId = urlSearchParams.get("machine");
    if (machineId && clientId) {
      console.log(clientId, machineId);
      setLoading(true);
      dispatch(desktop_login({ clientId, machineId }))
        .unwrap()
        .then(() => {
          navigate("/inventory");
          window.location.reload();
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [dispatch, navigate]);

  return (
    <div className="col-md-12 login-form">
      {message && (
        <div className="form-group">
          <div className="alert alert-danger" role="alert">
            {message}
          </div>
        </div>
      )}
    </div>
  );
};

export default DesktopLogin;
