import axios from "../common/AuthInterceptor";
import authHeader from "./auth-header";

const EMS_PARTS_API_URL = process.env.REACT_APP_API_URL + "/ems-parts/";

const getPartsByPartsNumber = (clientId, pn) => {
  return axios.get(EMS_PARTS_API_URL + clientId + "/pn?pn=" + encodeURIComponent(pn), {headers: authHeader() })
}

const getMakes = (make) => {
  return axios.get(EMS_PARTS_API_URL + "makes?make=" + encodeURIComponent(make), {headers: authHeader() })
}

const getModels = (makeId) => {
  return axios.get(EMS_PARTS_API_URL + "models?make=" + makeId, {headers: authHeader() })
}

const partsService = {
  getPartsByPartsNumber,
  getMakes,
  getModels
};

export default partsService