import React, {useState} from 'react';
import {Col, Container, Modal, Row} from "react-bootstrap";
import {toast, ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import partsService from "../../services/parts.service";
import Toaster from "../../common/ToastMsg";
import PartsForm from "./PartsForm";
import {cancelLoader, setLoader} from "../../slices/spinner";
import {useDispatch} from "react-redux";
import NumericInput from "react-numeric-input";
import inventoryService from "../../services/inventory.service";

const SearchForm = ({closeSearch, clientId, refreshInventory}) => {
    const dispatch = useDispatch();

    const [showMe, setShowMe] = useState(true);
    const [searchPn, SetSearchPn] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [autoHide, setAutoHide] = useState(false);
    const [showNew, setShowNew] = useState(false);

    const [qty, setQty] = useState(0);

    const [isPartsOpen, setIsPartsOpen] = useState(false);
    const [listPrice, setListPrice] = useState(0);
    const [cost, setCost] = useState(0);
    const [location, setLocation] = useState("");
    const [description, setDescription] = useState("");
    const [makeId, setMakeId] = useState(0);
    const [modelId, setModelId] = useState(0);
    const [showSearchMake, setShowSearchMake] = useState(false);

    const [searchMakeValue, setSearchMakeValue] = useState("");

    const [searchMakeResults, setSearchMakeResults] = useState([]);
    const [searchModelResults, setSearchModelResults] = useState([]);


    const closePartsModal = (msg) => {
        setShowMe(true);
        if (msg) {
            toast.info(msg, {
                position: "top-center",
                containerId: "inventory",
                autoClose: 2000
            });
        }
        setIsPartsOpen(false);
        closeSearch();
   }

    const showPartsModal = (sr) => {
        setSearchResults(sr);
        setShowMe(false);
        setIsPartsOpen(true);
    }

    const handleSearchByPn = () => {
        if (!searchPn) {
            toast.warn("Please specify parts number!", {
                position: "top-center",
                containerId: "search",
                autoClose: 2000
            });
            return;
        }
        dispatch(setLoader());
        SetSearchPn(searchPn.trim());
        partsService.getPartsByPartsNumber(clientId, searchPn.trim()).then(
            (response) => {
                if (response.data && response.data instanceof Array) {
                    setSearchResults(response.data);
                    if (response.data.length <= 0) {
                        toast.warn("parts number not found!", {
                            position: "top-center",
                            containerId: "search",
                            autoClose: 2000
                        });
                        setShowNew(true);
                    } else {
                        setShowNew(false);
                        showPartsModal(response.data);
                    }
                }
                dispatch(cancelLoader());
            },
            (error) => {
                console.log(error);
                Toaster.error({
                    title: "Failed to search by parts number !",
                    text: error.response.data || error.message
                }, {
                    position: "top-center",
                    containerId: "search"
                });
                dispatch(cancelLoader());
            }
        );
    }

    const handleSubmit = () => {
        if (qty <= 0) {
            toast.warn("Please specify quantity!", {
                position: "top-center",
                containerId: "search",
                autoClose: 2000
            });
            return;
        }
        if (!description) {
            toast.warn("Please specify description!", {
                position: "top-center",
                containerId: "search",
                autoClose: 2000
            });
            return;
        }
        if (makeId === 0 && modelId === 0 && !showSearchMake) {
            // make and model not specified, normal
        } else {
            if (makeId === 0) {
                toast.warn("Please specify make!", {
                    position: "top-center",
                    containerId: "search",
                    autoClose: 2000
                });
                return;
            }
            if (modelId === 0) {
                toast.warn("Please specify model!", {
                    position: "top-center",
                    containerId: "search",
                    autoClose: 2000
                });
                return;
            }
        }
        SetSearchPn(searchPn.trim());
        setDescription(description.trim());
        setLocation(location.trim());
        dispatch(setLoader());
        inventoryService.addParts(clientId, searchPn.trim(), description.trim(), qty, listPrice, cost, location.trim(), makeId, modelId).then(
            response => {
                dispatch(cancelLoader());
                refreshInventory();
                closeSearch("inventory has been updated! The new inventory ID is " + response.data);
            },
            error => {
                dispatch(cancelLoader());
                console.log(error);
                Toaster.error({
                    title: "Failed to add parts !",
                    text: error.response.data || error.message
                }, {
                    position: "top-center",
                    containerId: "search"
                });
            })
    }

    const handleSearchMake = () => {
        setMakeId(0);
        setModelId(0);
        setShowSearchMake(true);
    }

    const doSearchMake = () => {
        setSearchMakeValue(searchMakeValue.trim());
        if (!(searchMakeValue.trim())) {
            toast.warn("Please specify make!", {
                position: "top-center",
                containerId: "search",
                autoClose: 2000
            });
            return;
        }
        dispatch(setLoader());

        partsService.getMakes(searchMakeValue.trim()).then(
            (response) => {
                if (response.data && response.data instanceof Array) {
                    if (response.data.length <= 0) {
                        toast.warn("make not found!", {
                            position: "top-center",
                            containerId: "search",
                            autoClose: 2000
                        });
                    } else {
                        setSearchMakeResults(response.data);
                        setMakeId(response.data[0].id);
                        doSearchModel(response.data[0].id);
                    }
                }
                dispatch(cancelLoader());
            },
            (error) => {
                console.log(error);
                Toaster.error({
                    title: "Failed to fetch make !",
                    text: error.response.data || error.message
                }, {
                    position: "top-center",
                    containerId: "search"
                });
                dispatch(cancelLoader());
            }
        );
    }

    const doSearchModel = (makeId) => {
        dispatch(setLoader());
        partsService.getModels(makeId).then(
            (response) => {
                if (response.data && response.data instanceof Array) {
                    if (response.data.length <= 0) {
                        toast.warn("model not found!", {
                            position: "top-center",
                            containerId: "search",
                            autoClose: 2000
                        });
                    } else {
                        setSearchModelResults(response.data);
                        setModelId(response.data[0].id);
                    }
                }
                dispatch(cancelLoader());
            },
            (error) => {
                console.log(error);
                Toaster.error({
                    title: "Failed to fetch model !",
                    text: error.response.data || error.message
                }, {
                    position: "top-center",
                    containerId: "search"
                });
                dispatch(cancelLoader());
            }
        );
    }

    return (
        <div>
            <Modal show={showMe} onHide={closeSearch} fullscreen={"md-down"}>
                <Modal.Header closeButton>
                    <Modal.Title>Find Parts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ToastContainer containerId={"search"} autoClose={autoHide} />
                    <form>
                        <Container fluid>
                            <Row>
                                <Col xs={12} md={4}>
                                    <label htmlFor="pn" className="form-label">
                                        Parts Number
                                    </label>
                                </Col>
                                <Col xs={12} md={8}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="pn"
                                        name="pn"
                                        value={searchPn}
                                        onChange={(event) => {
                                            setShowNew(false);
                                            SetSearchPn(event.target.value);
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="mb-3">
                                        <button type="button" className="btn btn-primary mt-2"
                                                onClick={handleSearchByPn}>
                                            Search By Parts Number
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </form>
                    { showNew && (
                    <form>
                        <Container fluid>
                            <Row>
                                <Col xs={12} md={8}>
                                    <div className="mb-3">
                                        <label htmlFor="qty" className="form-label">
                                            QTY
                                        </label>
                                        <NumericInput min={0} value={qty} precision={0} className="form-control"
                                                      onChange={(vn, vs, _) => setQty(vn)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={8}>
                                <div className="mb-3">
                                        <label htmlFor="listPrice" className="form-label">
                                            List Price
                                        </label>
                                        <NumericInput min={0} value={listPrice} precision={2} className="form-control"
                                                      onChange={(vn, vs, _) => setListPrice(vn)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={8}>
                                    <div className="mb-3">
                                        <label htmlFor="cost" className="form-label">
                                            Cost
                                        </label>
                                        <NumericInput min={0} value={cost} precision={2} className="form-control"
                                                      onChange={(vn, vs, _) => setCost(vn)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={4}>
                                    <label htmlFor="makeOption1" className="form-label">
                                        Make Undefined
                                    </label>
                                    <input
                                        type="radio"
                                        id="makeOption1"
                                        name="makeOptions"
                                        value={0}
                                        defaultChecked
                                        onChange={(event) => {
                                                setMakeId(0);
                                                setModelId(0);
                                                setSearchMakeValue("");
                                                setSearchMakeResults([]);
                                                setSearchModelResults([]);
                                                setShowSearchMake(false);
                                            }
                                        }
                                    />
                                </Col>
                                <Col xs={12} md={4}>
                                    <label htmlFor="makeOption2" className="form-label">
                                        Search Make
                                    </label>
                                    <input
                                        type="radio"
                                        id="makeOption2"
                                        name="makeOptions"
                                        value="makeOption2"
                                        onChange={handleSearchMake}
                                    />
                                </Col>
                            </Row>
                            {
                                showSearchMake && (
                                    <Row>
                                        <Col xs={12} md={8}>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="make"
                                                name="make"
                                                value={searchMakeValue}
                                                onChange={(event) => setSearchMakeValue(event.target.value)}
                                            />
                                        </Col>
                                        <Col xs={12} md={2}>
                                            <button type="button" className="btn btn-primary" onClick={doSearchMake}>Search</button>
                                        </Col>
                                    </Row>
                                )
                            }
                            {
                                showSearchMake && (searchMakeResults.length > 0) && (
                                    <Row>
                                        <Col xs={12} md={8}>
                                            <select
                                                value={makeId}
                                                className="form-select mt-2"
                                                onChange={(event) => {
                                                        setMakeId(event.target.value);
                                                        doSearchModel(event.target.value);
                                                    }
                                                }
                                            >
                                                {searchMakeResults.map((make, index) => (
                                                    <option value={make.id}>
                                                        {make.value}
                                                    </option>
                                                ))}
                                            </select>
                                        </Col>
                                    </Row>
                                )
                            }
                            {
                                showSearchMake && (searchModelResults.length > 0) && (
                                    <Row>
                                        <Col xs={12} md={8}>
                                            <select
                                                value={modelId}
                                                className="form-select mt-2"
                                                onChange={(event) => setModelId(event.target.value)}
                                            >
                                                {searchModelResults.map((model, index) => (
                                                    <option value={model.id}>
                                                        {model.value}
                                                    </option>
                                                ))}
                                            </select>
                                        </Col>
                                    </Row>
                                )
                            }
                            <Row>
                                <Col xs={12} md={8}>
                                    <div>
                                        <label>Description</label>
                                        <textarea className="form-control"
                                                  value={description}
                                                  onChange={(event) => setDescription(event.target.value)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={8}>
                                    <div>
                                        <label>Location</label>
                                        <textarea className="form-control"
                                                  value={location}
                                                  onChange={(event) => setLocation(event.target.value)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="mb-3">
                                        <button type="button" className="btn btn-primary mt-2" onClick={handleSubmit}>
                                            Add Parts
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </form>)}
                </Modal.Body>
            </Modal>
            {isPartsOpen && (
                <PartsForm
                    closeModal={closePartsModal} items={searchResults} clientId={clientId}
                    refreshInventory={refreshInventory}
                />
            )}
        </div>
    );
};

export default SearchForm;