import {Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import './CustomSizeModal.css';
import inventoryService from "../../services/inventory.service";
import {toast, ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Toaster from "../../common/ToastMsg";
import NumericInput from 'react-numeric-input';
import {useDispatch} from "react-redux";
import {cancelLoader, setLoader} from "../../slices/spinner";

const PartsForm = ({ closeModal, items, clientId, refreshInventory }) => {
    const dispatch = useDispatch();

    const [autoHide, setAutoHide] = useState(false);
    const [selectedItem, setSelectedItem] = useState(-1);
    const [inv, setInv] = useState(undefined);

    const [qty, setQty] = useState(0);
    const [listPrice, setListPrice] = useState(0);
    const [cost, setCost] = useState(0);
    const [location, setLocation] = useState("");

    useEffect(() => {
        if (items.length == 1) {
            handleCheck({target : { value: items[0].id}})
        }
    }, [])

    const handleCheck = r => {
        setSelectedItem(r.target.value);
        const p = items.find(p => p.id === r.target.value * 1);

        setInv(undefined);
        setQty(1);
        setListPrice(p.price ?? 0);
        setCost(0);
        setLocation("");
    }

    const handleSubmit = () => {
        dispatch(setLoader());

        let xId = inv == undefined ? -1 : inv.id;
        inventoryService.updateInventory(clientId, xId, selectedItem, qty, cost, listPrice, location, true).then(
            response => {
                dispatch(cancelLoader());
                refreshInventory();
                closeModal("inventory has been updated! The new inventory ID is " + response.data);
            },
            error => {
                dispatch(cancelLoader());
                console.log(error);
                Toaster.error({
                    title: "Failed to update inventory !",
                    text: error.message
                }, {
                    position: "top-center",
                    containerId: "parts"
                });
            }
        )
    }

    return (
            <Modal show={true} onHide={closeModal}  fullscreen={"md-down"}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Parts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ToastContainer containerId={"parts"} autoClose={autoHide} />
                    <div className="row mt-5">
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">PN#</th>
                            <th scope="col">Parts</th>
                        </tr>
                        </thead>
                        <tbody>
                        {items && items instanceof Array && items.length > 0 && items.map((item, index) => (
                            <tr>
                                <td>
                                    <input
                                        type="radio"
                                        value={item.id}
                                        checked={selectedItem * 1 === item.id * 1}
                                        onChange={handleCheck}
                                    />
                                </td>
                                <td>{item.partsNumber}</td>
                                <td>
                                    {item.partsNames.slice(0, 5).map((partName, index) => (
                                        <p key={index}>{partName}</p>
                                    ))}
                                    {item.partsNames.length > 5 &&
                                        <p>
                                            <span title={item.partsNames.join('\n')}>...</span>
                                        </p>
                                    }
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    </div>
                    <form>
                        <div className="mb-3">
                            <label htmlFor="qty" className="form-label">
                                QTY
                            </label>
                            <NumericInput min={0} value={qty} precision={0} className="form-control"
                                      onChange={(vn, vs, _) => setQty(vn)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="listPrice" className="form-label">
                                List Price
                            </label>
                            <NumericInput min={0} value={listPrice} precision={2} className="form-control"
                                          onChange={(vn, vs, _) => setListPrice(vn)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="cost" className="form-label">
                                Cost
                            </label>
                            <NumericInput min={0} value={cost} precision={2} className="form-control"
                                          onChange={(vn, vs, _) => setCost(vn)}
                            />
                        </div>
                        <div>
                            <label>Location</label>
                            <textarea className="form-control"
                                      value= {location}
                                      onChange={(event) => setLocation(event.target.value)}
                            />
                        </div>
                    { selectedItem > 0 &&

                    <button type="button" className="btn btn-primary mt-2"  onClick={handleSubmit}>
                        {inv ? "Update Parts" : "Add Parts"}
                    </button>
                    }
                </form>
            </Modal.Body>
        </Modal>
    );

};

export default PartsForm;