import React, {useEffect, useState} from "react";
import { Container, Row, Col } from 'react-bootstrap';

import UserService from "../services/user.service";
import {Navigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import inventoryService from "../services/inventory.service";
import InventoryItem from "./InventoryItem";
import SearchForm from "./inventory/SearchForm";
import {ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import {cancelLoader, setLoader} from "../slices/spinner";
import Toaster from "../common/ToastMsg";

const Inventory = () => {
    const dispatch = useDispatch();
    const [clients, setClients] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const { user: currentUser } = useSelector((state) => state.auth);
    const [inventory, setInventory] = useState([]);
    const [displayInv, setDisplayInv] = useState([]);

    const [filterParts, setFilterParts] = useState("");
    const [filterNumber, setFilterNumber] = useState("");
    const [filterLocation, setFilterLocation] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentEditItem, setCurrentEditItem] = useState({id: undefined, partsId: undefined, partsName: undefined, partsNumber: undefined, location: undefined, qty: undefined});

    const [timer, setTimer] = useState(null);
    const openModal = () => {
        setCurrentEditItem({id: undefined, partsId: undefined, partsName: undefined, partsNumber: undefined, location: undefined, qty: undefined});
        setIsModalOpen(true);
    };

    const closeModal = (msg) => {
        if (msg) {
            Toaster.info({
                text: msg
            },{
                position: "top-center",
                containerId: "inventory",
                autoClose: 3000
            });
        }
        setIsModalOpen(false);
        refreshInventory();
    };

useEffect(() => {
    dispatch(setLoader());
    UserService.getClientList().then(
      (response) => {
        setClients(response.data);
        if (response.data instanceof Array && response.data.length > 0) {
            setSelectedOption(response.data[0].oid);
        }
          dispatch(cancelLoader());
      },
      (error) => {
          console.log(error);
          Toaster.error({
              title: "Failed to load clients !",
              text: error.message
          }, {
              position: "top-center",
              containerId: "search"
          });
          dispatch(cancelLoader());
      }
    )

  }, []);

  const refreshInventory = () => {
      dispatch(setLoader("loading inventory"));
        inventoryService.getClientInventory(selectedOption).then(
            (response) => {
                setInventory(response.data);
                setDisplayInv(response.data);
                setFilterParts("");
                setFilterNumber("");
                setFilterLocation("");
                dispatch(cancelLoader());
            },
            (error) => {
                console.log(error);
                Toaster.error({
                    title: "Failed to load inventory !",
                    text: error.message
                }, {
                    position: "top-center",
                    containerId: "search"
                });
                dispatch(cancelLoader());
            }
        )
    }

  const refreshInventory1 = () => {
      setInventory([]);
      setDisplayInv([]);
      setFilterParts("");
      setFilterNumber("");
      setFilterLocation("");
  };
  useEffect(() => {
    if (!currentUser || !selectedOption) return;
    refreshInventory();
  }, [selectedOption, currentUser]);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  const handleSelection = (event) => {
    setSelectedOption(event.target.value);
  };

  const updateFilter = (n, p, l) => {
      let d = inventory;
      if (n !== "") {
          d = d.filter(i => i.emsParts.partsNumber.toLowerCase().includes(n.trim().toLowerCase()))
      }
      if (p !== "") {
          d = d.filter(i => i.emsParts.partsNames.find(pn => pn.toLowerCase().includes(p.trim().toLowerCase())));
      }
      if (l !== "") {
          d = d.filter(i => i.location.toLowerCase().includes(l.trim().toLowerCase()))
      }
      setDisplayInv(d);

  }
    const handleFilterPartsChange = (event) => {
        setFilterParts(event.target.value);
        clearTimeout(timer);
        // Set a new timeout of 0.5 seconds
        const newTimer = setTimeout(() => updateFilter(filterNumber, event.target.value, filterLocation), 1000);
        setTimer(newTimer);
    }

    const handleFilterNumberChange = (event) => {
        setFilterNumber(event.target.value);
        clearTimeout(timer);
        // Set a new timeout of 0.5 seconds
        const newTimer = setTimeout(() => updateFilter(event.target.value, filterParts, filterLocation), 1000);
        setTimer(newTimer);
    }

    const handleFilterLocationChange = (event) => {
        setFilterLocation(event.target.value);
        clearTimeout(timer);
        // Set a new timeout of 0.5 seconds
        const newTimer = setTimeout(() => updateFilter(filterNumber, filterParts, event.target.value), 1000);
        setTimer(newTimer);
    }

    const handleClearFilter = () => {
        setFilterParts("");
        setFilterNumber("");
        setFilterLocation("");
        updateFilter("","","");
    }

    return (
        <Container fluid>
            <ToastContainer autoClose={false} containerId={"inventory"} />
            <Row>
                <Col xs={12} md={8}>
                    <div className="dropdown mb-2">
                        <select
                            value={selectedOption}
                            className="form-select"
                            onChange={handleSelection}
                        >
                            {!clients &&
                                <option value="" disabled>
                                    No client found
                                </option>
                            }

                            {clients && clients.map((client, index) => (
                                <option value={client.oid}>
                                    {client.clientName}
                                </option>
                            ))}

                        </select>
                    </div>
                </Col>
                <Col xs={12} md={4}>
                    <div className="container">
                        <button type="button" className="btn btn-primary" onClick={openModal}>Add New Parts
                        </button>
                        {isModalOpen && (
                            <SearchForm
                                closeSearch={closeModal} clientId={selectedOption} refreshInventory={refreshInventory1}
                            />
                        )}
                    </div>
                    {/* Your content for column 2 */}
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="row mt-5 table-responsive">
                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Parts Number</th>
                                <th scope="col">Parts</th>
                                <th scope="col">Location</th>
                                <th scope="col" style={{paddingRight: 50}}>Qty</th>
                                <th scope="col" style={{paddingRight: 60}}>List Price</th>
                                <th scope="col" style={{paddingRight: 60}}>Cost</th>
                                <th scope="col">Action</th>
                            </tr>
                            </thead>
                            <tbody>

                            <tr className="table-secondary">
                                <td></td>
                                <td><input type="text" value={filterNumber} onChange={handleFilterNumberChange}/></td>
                                <td><input type="text" value={filterParts} onChange={handleFilterPartsChange}/></td>
                                <td><input type="text" value={filterLocation} onChange={handleFilterLocationChange}/></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="col-8">
                                    <button onClick={handleClearFilter}>Clear Filter</button>
                                </td>
                            </tr>

                            {displayInv && displayInv instanceof Array && displayInv.length > 0 && displayInv.map((invItem, index) => (
                                <InventoryItem key={invItem.id} clientId={selectedOption} item={invItem}
                                               inv={displayInv}/>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Inventory;
