import React, {useEffect} from "react";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
const SubscriptionsResult = () => {
    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            console.log("get success");
            // setSessionId(query.get("session_id"));
            toast.success("Subscription created!", {
                position: "top-center",
                autoClose: 3000, // Close the toast after 2000 milliseconds (2 seconds)
            });
        }

        if (query.get('canceled')) {
            toast.error("Subscription Failed!", {
                position: "top-center",
                autoClose: 3000, // Close the toast after 2000 milliseconds (2 seconds)
            });
        }
    }, []);

    setTimeout(function() {
        window.location.href = '/subscriptions-stripe';
    }, 3000);

    return (
        <div>
            <ToastContainer />
        </div>
    )
}

export  default SubscriptionsResult;