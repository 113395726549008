import { configureStore } from "@reduxjs/toolkit"
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import spinnerReducer from "./slices/spinner";

const reducer = {
  auth: authReducer,
  message: messageReducer,
  spinner: spinnerReducer
}

const store = configureStore({
  reducer: reducer,
  devTools: true,
})

export default store;
