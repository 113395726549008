import React from "react";
import {toast} from 'react-toastify';

export const ToastMsg = ({ title, text }) => {
    return (
        <div className="msg-container">
            <p className="msg-title">{title}</p>
            <p className="msg-description">{text}</p>
        </div>
    );
};

const Toaster = (myProps, toastProps) =>
    toast(<ToastMsg {...myProps} />, { ...toastProps });

Toaster.error = (myProps, toastProps) =>
    toast.error(<ToastMsg {...myProps} />, { ...toastProps });

Toaster.success = (myProps, toastProps) =>
    toast.success(<ToastMsg {...myProps} />, { ...toastProps });

Toaster.warn = (myProps, toastProps) =>
    toast.warn(<ToastMsg {...myProps} />, { ...toastProps });

Toaster.info = (myProps, toastProps) =>
    toast.info(<ToastMsg {...myProps} />, { ...toastProps });

export default Toaster;
