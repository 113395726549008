import React, {useEffect, useState} from "react";

import userService from "../../services/user.service";
import {Navigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {clearMessage} from "../../slices/message";
import SubscriptionListItem from "./SubScriptionListItem";
/*
 import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
*/
const SubscriptionsStripe = () => {
  const STRIPE_SUBMIT_URL = process.env.REACT_APP_API_URL + "/stripe/create-checkout-session";
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [responseCode, setResponseCode] = useState(400);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [clientsSubs, setClientsSubs] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedSub, setSelectedSub] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [proration, setProration] = useState(true);

  const dispatch = useDispatch();

  const date = new Date();
  const firstDayOfNextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  const unixTimestamp = Math.floor(firstDayOfNextMonth.getTime() / 1000);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.accessToken) {
      setAccessToken(user.accessToken);
    }
    loadClient();
    // loadSubscription();
    refreshSubInfo();
  }, []);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

/*  const loadSubscription = () => {
    setLoading(true);
    userService.getSubscription().then(
        (response) => {
          setSubs(response.data);
          setLoading(false);
        },
        (error) => {
          const _content =
              (error.response &&
                  error.response.data) ||
              error.message ||
              error.toString();
          console.log(error);
          setMessage(_content);
          setLoading(false);
        }
    );
  };*/
  const loadClient = () => {
    setLoading(true);
    userService.getClientList().then(
        (response) => {
          let ca = [];
          if (response.data && response.data instanceof Array) {
            setContent(response.data);
            setClients(response.data);

          }
          setLoading(false);
          setResponseCode(200);
        },
        (error) => {
          const _content =
              (error.response &&
                  error.response.data) ||
              error.message ||
              error.toString();
          console.log(error);

          setContent(_content);
          setResponseCode(error.response.status);
          setLoading(false);
        }
    );
  }
  const refreshSubInfo = () => {
    setLoading(true);
    userService.getClientsSubs().then(
        (response) => {
          setClientsSubs(response.data);
          setResponseCode(200);
          setLoading(false);
        },
        (error) => {
          const _content =
              (error.response &&
                  error.response.data) ||
              error.message ||
              error.toString();
        console.log(error);

          setContent(_content);
          setResponseCode(error.response.status);
          setLoading(false);
        }
    );
  }

  const handleCheckboxChange = (event) => {
    setProration(event.target.checked);
  }
  const handleItemSelect = (item) => {
    setSelectedItem(item);
    setSelectedClient(item.oid);
    setSelectedSub(item.subId);
  }

/*
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedDateString(format(date, "MM-dd-yyyy"));
  }
*/

  return (
      <div>
        {
            clients.length > 0 &&
                <div className="container">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex container">
                        <div className="container">
                          <div className="row mt-4">
                            <table className="table">
                              <thead>
                              <tr>
                                <th scope="col">Client</th>
                                <th scope="col">Subscription</th>
                                <th scope="col">Price</th>
                              </tr>
                              </thead>
                              <tbody>
                              {
                                  clients.length > 0 && (
                                      clients.map((c, index) => (
                                          <SubscriptionListItem key={c.oid + c.subId} item={c} handleCheck={handleItemSelect}/>
                                      )))
                              }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                      <div>
                          {loading && (
                              <span className="spinner-border spinner-border-sm"></span>
                          )}
                      </div>
                      {
                          selectedItem && (
                              <div className="container mt-3">
                                  <form action={STRIPE_SUBMIT_URL} method="POST">
                                      {/* Add a hidden field with the lookup_key of your Price */}
                                      <input type="hidden" name="sub_id" value={selectedSub} />
                                      <input type="hidden" name="client_id" value={selectedClient} />
                                      <input type="hidden" name="access_token" value={accessToken} />
                                    <input type="hidden" name="time_zone" value={Intl.DateTimeFormat().resolvedOptions().timeZone} />
                                    <input type="hidden" name="next_month" value={unixTimestamp} />
                                    <div style={{ display: 'flex', alignItems: 'center'}}>
                                      <label style={{ marginRight: '10px', marginBottom: '10px' }}>
                                        <input type="checkbox" name="proration" checked={proration} onChange={handleCheckboxChange} />
                                        Proration: Start subscription on
                                      </label>
                                      {
                                        proration && <span>today</span>
/*
                                          <DatePicker name="start_date"
                                              selected={selectedDate}
                                              onChange={date => handleDateChange(date)}
                                              minDate={new Date()}
                                              maxDate={endOfMonth}
                                              popperPlacement="top-start"
                                              popperModifiers={{
                                                preventOverflow: {
                                                  enabled: true,
                                                  escapeWithReference: false,
                                                  boundariesElement: 'viewport',
                                                },
                                                flip: {
                                                  enabled: true,
                                                },
                                                offset: {
                                                  enabled: true,
                                                  offset: '5px, 10px'
                                                },
                                              }}
                                              style={{zIndex: 9999}}
                                          />
*/
                                      }
                                      {
                                        !proration && <span>1st of next month</span>
                                      }
                                    </div>

                                    <button id="checkout-and-portal-button" className="btn-primary" type="submit">
                                          Subscribe
                                      </button>
                                  </form>
                              </div>
                          )
                      }
                  </div>
                </div>
        }
        {responseCode >= 400 && content && (
            <div className="form-group pt-50">
              <div
                  className="alert alert-danger"
                  role="alert"
              >
                {
                  (typeof content) === "string" ? content : (content.hasOwnProperty("message") ? content.message : "something wrong")
                }
              </div>
            </div>
        )}

        <div className="row mt-5">
          <table className="table">
            <thead>
            <tr>
              <th scope="col">Client</th>
              <th scope="col">Subscription</th>
              <th scope="col">Start Date</th>
              <th scope="col">End Date</th>
              <th scope="col">Payment ID</th>
              <th scope="col">
                <div className="container mt-3">
                  <button className="btn-primary" onClick={() => refreshSubInfo()}>Refresh</button>
                </div>
              </th>
            </tr>
            </thead>
            <tbody>

            {clientsSubs && clientsSubs instanceof Array && clientsSubs.length > 0 && clientsSubs.map((cs, index) => (
                <tr>
                  <th scope="row">{cs.clientName}</th>
                  <td>{cs.subscriptionName}</td>
                  <td>{cs.startDate}</td>
                  <td>{cs.endDate}</td>
                  <td>{cs.paymentId}</td>
                </tr>

            ))}
            </tbody>
          </table>
        </div>
      </div>

  );
};

export default SubscriptionsStripe;
