import React, {useCallback, useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {BrowserRouter as Router, Link, Route, Routes} from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import {Container, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";

import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";
import Profile from "./components/Profile";
import BoardUser from "./components/BoardUser";
import BoardModerator from "./components/BoardModerator";
import BoardAdmin from "./components/BoardAdmin";

import {logout} from "./slices/auth";

import EventBus from "./common/EventBus";
import ChangePassword from "./components/ChangePassword";
import ForgetPassword from "./components/ForgetPassword";
import ResetPassword from "./components/ResetPassword";
import RegisterClient from "./components/RegisterClient";
import ClientSubscriptions from "./components/ClientSubscriptions";
import SubscriptionsMoneris from "./components/moneris/SubscriptionsMoneris";
import Checkout from "./components/Checkout";
import PaymentDeclined from "./components/moneris/PaymentDeclined";
import PaymentApproved from "./components/moneris/PaymentApproved";
import Receipt from "./components/moneris/Receipt";
import PaymentValidation from "./components/moneris/PaymentValidation";
import CreateUser from "./components/CreateUser";
import ClientDiscount from "./components/ClientDiscount";
import ResetUserPassword from "./components/ResetUserPassword";
import Inventory from "./components/Inventory";
import SubscriptionsStripe from "./components/stripe/SubscriptionsStripe";
import SubscriptionsResult from "./components/SubscriptionsResult";
import DesktopLogin from "./components/DesktopLogin";

const App = (props) => {
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [navExpanded, setNavExpanded] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      setShowModeratorBoard(currentUser.roles.includes("ROLE_MODERATOR"));
      setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
    } else {
      setShowModeratorBoard(false);
      setShowAdminBoard(false);
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  const [toggleClicked, setToggleClicked] = useState(false);

  const handleToggle = () => {
    setToggleClicked(!toggleClicked);
    setNavExpanded(!navExpanded);
  };

  const closeMenu = () => {
    setToggleClicked(false);
    setNavExpanded(false);
  };

  return (
      <LoadingOverlay
          active={props.isActive}
          spinner
          text={props.overlayMessage}
      >
    <Router>
      <div>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" expanded={navExpanded} onToggle={handleToggle}>
          <Container>
            <Navbar.Brand as={Link} to="/" onClick={() => closeMenu()}>PartsKick</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link as={Link} to="/home" onClick={() => closeMenu()}>Home</Nav.Link>
                {showAdminBoard && (
                    <NavDropdown title="Admin" id="admin-nav-dropdown">
                      <NavDropdown.Item as={Link} to="/createUser" onClick={() => closeMenu()}>Create *Owner*</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/resetUserPassword" onClick={() => closeMenu()}>Reset User Password</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/clientDiscount" onClick={() => closeMenu()}>Set Client Discount</NavDropdown.Item>
                    </NavDropdown>
                )}
                {showAdminBoard && (
                    <NavDropdown title="Subscriptions" id="subscriptions-nav-dropdown">
                      <NavDropdown.Item as={Link} to="/subscriptions-moneris" onClick={() => closeMenu()}>Moneris</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/subscriptions-stripe" onClick={() => closeMenu()}>Stripe</NavDropdown.Item>
                    </NavDropdown>
                )}
                <Nav.Link as={Link} to="/inventory" onClick={() => closeMenu()}>Inventory</Nav.Link>
                {showModeratorBoard && (
                    <Nav.Link as={Link} to="/mod" onClick={() => closeMenu()}>Moderator Board</Nav.Link>
                )}
              </Nav>
              {currentUser ? (
                  <Nav>
                    <Nav.Link as={Link} to="/profile" onClick={() => closeMenu()}>{currentUser.username}</Nav.Link>
                    <Nav.Link as={Link} to="/login" onClick={logOut}>LogOut</Nav.Link>
                  </Nav>
              ) : (
                  <Nav>
                    <Nav.Link as={Link} to="/login" onClick={() => closeMenu()}>Login</Nav.Link>
                    <Nav.Link as={Link} to="/register" onClick={() => closeMenu()}>Sign Up</Nav.Link>
                  </Nav>
              )}
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div className="container mt-3">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/user" element={<BoardUser />} />
            <Route path="/mod" element={<BoardModerator />} />
            <Route path="/admin" element={<BoardAdmin />} />
            <Route path="/changePassword" element={<ChangePassword />} />
            <Route path="/forgetPassword" element={<ForgetPassword />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/registerNewClient" element={<RegisterClient />} />
            <Route path="/manageSubscription" element={<ClientSubscriptions />} />
            <Route path="/subscriptions-moneris" element={<SubscriptionsMoneris />} />
            <Route path="/subscriptions-stripe" element={<SubscriptionsStripe />} />
            <Route path="/subscriptionsResult" element={<SubscriptionsResult />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/payment/declined" element={<PaymentDeclined />} />
            <Route path="/payment/approved" element={<PaymentApproved />} />
            <Route path="/payment/validation" element={<PaymentValidation />} />
            <Route path="/payment/receipt" element={<Receipt />} />
            <Route path="/createUser" element={<CreateUser />} />
            <Route path="/clientDiscount" element={<ClientDiscount />} />
            <Route path="/resetUserPassword" element={<ResetUserPassword />} />
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/desktop/login" element={<DesktopLogin />} />
          </Routes>
        </div>
      </div>
    </Router>
      </LoadingOverlay>
  );
};

const mapStateToProps = state => {
  return {
    isActive:  state.spinner.isActive,
    overlayMessage: state.spinner.overlayMessage
  }
}

export default connect(mapStateToProps)(App);
