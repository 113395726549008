import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isActive: false,
    overlayMessage: "loading..."
};

const spinnerSlice = createSlice({
    name: "spinner",
    initialState,
    reducers: {
        setLoader: (state, action) => {
            return {...state, isActive: true, overlayMessage: action.payload ??= "loading..."}
        },
        cancelLoader: (state, action) => {
            return {...state, isActive: false}
        }
    }
})

const { reducer, actions} = spinnerSlice;

export const { setLoader, cancelLoader} = actions;

export default reducer;