import React, {useState} from "react";
import NumericInput from "react-numeric-input";
import {useDispatch} from "react-redux";
import {cancelLoader, setLoader} from "../slices/spinner";
import inventoryService from "../services/inventory.service";
import _ from "lodash";
import Toaster from "../common/ToastMsg";
import {toast} from "react-toastify";

const InventoryItem = ({clientId, item, inv}) => {
    const [qty, setQty] = useState(item.qty);
    const [location, setLocation] = useState(item.location);
    const [listPrice, setListPrice] = useState(item.listPrice);
    const [cost, setCost] = useState(item.cost);
    const [isEdited, setIsEdited] = useState(false);
    const dispatch = useDispatch();
    const handleCancelClick = () => {
        console.log(item);
        setLocation(item.location);
        setCost(item.cost);
        setListPrice(item.listPrice);
        setQty(item.qty);
        setIsEdited(false);
    }

    const handleDeleteClick = () => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Are you sure you want to delete " + item.emsParts.partsName + " ## " + item.emsParts.partsNumber)) {
            dispatch(setLoader("Deleting..."));
            inventoryService.deleteParts(clientId, item.id).then(
                response => {
                    _.remove(inv, i => i.id === item.id);
                    dispatch(cancelLoader());
                    toast.info("inventory deleted!", {
                        position: "top-center",
                        containerId: "inventory",
                        autoClose: 2000
                    });
                },
                error => {
                    console.log(error);
                    dispatch(cancelLoader());
                    Toaster.error({
                        title: "Failed to delete inventory !",
                        text: error.message
                    }, {
                        position: "top-center",
                        containerId: "inventory",
                        autoClose: 3000
                    });
                }
            );
        }
    }

    const handleSaveClick = () => {
        if (qty !== item.qty || location !== item.location || cost !== item.cost || listPrice !== item.listPrice) {
            dispatch(setLoader("Updating inventory"))
            inventoryService.updateInventory(clientId, item.id, item.emsParts.id, qty, cost, listPrice, location, false).then(
                (response) => {
                    item.qty = qty;
                    item.location = location;
                    item.cost = cost;
                    item.listPrice = listPrice;
                    setIsEdited(false);
                    dispatch(cancelLoader());
                    toast.info("inventory updated!", {
                        position: "top-center",
                        containerId: "inventory",
                        autoClose: 2000
                    });
                },
                (error) => {
                    console.log(error);
                    dispatch(cancelLoader());
                    Toaster.error({
                        title: "Failed to update inventory !",
                        text: error.message
                    }, {
                        position: "top-center",
                        containerId: "inventory"
                    });
                }
            );
        } else {
            toast.warn("item doesn't change!", {
                position: "top-center",
                containerId: "inventory"
            });
        }
    };

    return (
        <tr>
            <td>*<strong>{item.id}</strong>*</td>
            <td>{item.emsParts.partsNumber}</td>
            <td>
                {item.emsParts.partsNames.slice(0, 5).map((partName, index) => (
                    <p key={index}>{partName}</p>
                ))}
                {item.emsParts.partsNames.length > 5 &&
                    <p>
                        <span title={item.emsParts.partsNames.join('\n')}>...</span>
                    </p>
                }
            </td>
            <td>
                <textarea className="border" rows="2" cols="40" value={location} onChange={e => {
                    setLocation(e.target.value);
                    setIsEdited(true);
                }}/>
            </td>
            <td>
                <NumericInput min={1} value={qty} precision={0} className="form-control"
                              onChange={(vn, vs, _) => {
                                  console.log("current value: " + vn + " previous value: " + vs + " qty: " + qty);
                                  setQty(vn);
                                  setIsEdited(true);
                              }
                              }
                />

            </td>
            <td>
                <NumericInput min={0} value={listPrice} precision={2} className="form-control"
                              onChange={(vn, vs, _) => {
                                  setListPrice(vn);
                                  setIsEdited(true);
                              }}
                />
            </td>
            <td>
                <NumericInput min={0} value={cost} precision={2} className="form-control"
                              onChange={(vn, vs, _) => {
                                  setCost(vn);
                                  setIsEdited(true);
                              }}
                />
            </td>
            <td>
                <div>
                    <button onClick={handleSaveClick} disabled={!isEdited} className="w-100 mb-1">Save</button>
                    {isEdited &&
                        <div>
                            <button onClick={handleCancelClick} disabled={!isEdited} className="w-100 mb-1">Cancel
                            </button>
                        </div>
                    }
                    <button onClick={handleDeleteClick} className="w-100">Delete</button>
                </div>
            </td>
        </tr>
    );
};

export default InventoryItem;